import { template as template_fc5c939047c24f9ebcc912d6eca25a53 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fc5c939047c24f9ebcc912d6eca25a53(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
