import { template as template_dcf9e17bb1004338a3868bb99e842a18 } from "@ember/template-compiler";
const FKText = template_dcf9e17bb1004338a3868bb99e842a18(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
