import { template as template_f724ab25404445488902fd4b338f84fc } from "@ember/template-compiler";
const FKLabel = template_f724ab25404445488902fd4b338f84fc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
